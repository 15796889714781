@import '@radix-ui/themes/tokens/base.css';
@import '@radix-ui/themes/tokens/colors/gray.css';
@import '@radix-ui/themes/tokens/colors/teal.css';
@import '@radix-ui/themes/tokens/colors/gold.css';
@import '@radix-ui/themes/tokens/colors/ruby.css';
@import '@radix-ui/themes/tokens/colors/blue.css';
@import '@radix-ui/themes/tokens/colors/mauve.css';
@import '@radix-ui/themes/tokens/colors/crimson.css';
@import '@radix-ui/themes/components.css';
@import '@radix-ui/themes/utilities.css';
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.scale-on-click {
	@apply transition-transform duration-100 transform active:scale-95;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.radix-themes {
	--default-font-family: var(--font-satoshi);
}

:root, .light, .light-theme {
	--violet-1: #f7f7fa;
	--violet-2: #f2f2fc;
	--violet-3: #ebeafd;
	--violet-4: #e0deff;
	--violet-5: #d5d1ff;
	--violet-6: #c7c1ff;
	--violet-7: #b4abff;
	--violet-8: #9b8cff;
	--violet-9: #6828ee;
	--violet-10: #5a1ed2;
	--violet-11: #612ddc;
	--violet-12: #321a73;

	--violet-a1: #3b3bfa04;
	--violet-a2: #e6e6ff66;
	--violet-a3: #e1e0ff99;
	--violet-a4: #e0deff;
	--violet-a5: #d5d1ff;
	--violet-a6: #c7c1ff;
	--violet-a7: #b4abff;
	--violet-a8: #9b8cff;
	--violet-a9: #4d01ecd7;
	--violet-a10: #4501cde1;
	--violet-a11: #4001d6d2;
	--violet-a12: #1c0164e5;

	--violet-contrast: #fff;
	--violet-surface: #f0f0fdcc;
	--violet-indicator: #6828ee;
	--violet-track: #6828ee;
}

@supports (color: color(display-p3 1 1 1)) {
	@media (color-gamut: p3) {
		:root, .light, .light-theme {
			--violet-1: oklch(97.7% 0.0038 287.2);
			--violet-2: oklch(96.5% 0.0131 287.2);
			--violet-3: oklch(94.3% 0.026 287.2);
			--violet-4: oklch(91.5% 0.053 287.2);
			--violet-5: oklch(88.3% 0.0782 287.2);
			--violet-6: oklch(84.2% 0.1018 287.2);
			--violet-7: oklch(78.4% 0.1283 287.2);
			--violet-8: oklch(70.5% 0.1704 287.2);
			--violet-9: oklch(50.6% 0.2624 287.2);
			--violet-10: oklch(45.6% 0.2412 287.2);
			--violet-11: oklch(48.6% 0.2412 287.2);
			--violet-12: oklch(31.4% 0.1415 287.2);

			--violet-a1: color(display-p3 0 0 0.9804 / 0.012);
			--violet-a2: color(display-p3 0.8235 0.8235 1 / 0.2);
			--violet-a3: color(display-p3 0.6863 0.6667 1 / 0.2);
			--violet-a4: color(display-p3 0.7255 0.7059 1 / 0.4);
			--violet-a5: color(display-p3 0.2353 0.1765 1 / 0.2);
			--violet-a6: color(display-p3 0.0863 0 0.9804 / 0.228);
			--violet-a7: color(display-p3 0.0902 0.0039 0.9686 / 0.316);
			--violet-a8: color(display-p3 0.102 0 0.9647 / 0.436);
			--violet-a9: color(display-p3 0.2471 0 0.8824 / 0.824);
			--violet-a10: color(display-p3 0.2235 0 0.7647 / 0.864);
			--violet-a11: color(display-p3 0.2039 0 0.7961 / 0.808);
			--violet-a12: color(display-p3 0.0863 0 0.3647 / 0.892);

			--violet-contrast: #fff;
			--violet-surface: color(display-p3 0.9412 0.9412 0.9843 / 0.8);
			--violet-indicator: oklch(50.6% 0.2624 287.2);
			--violet-track: oklch(50.6% 0.2624 287.2);
		}
	}
}


:root,
.light,
.light-theme {
	--gray-1: #f7f7f7;
	--gray-2: #f3f3f3;
	--gray-3: #eaeaea;
	--gray-4: #e2e2e2;
	--gray-5: #dadada;
	--gray-6: #d2d2d2;
	--gray-7: #c6c6c6;
	--gray-8: #b4b4b4;
	--gray-9: #848484;
	--gray-10: #7a7a7a;
	--gray-11: #5d5d5d;
	--gray-12: #1f1f1f;

	--gray-a1: #00000003;
	--gray-a2: #00000007;
	--gray-a3: #00000010;
	--gray-a4: #00000018;
	--gray-a5: #00000021;
	--gray-a6: #00000029;
	--gray-a7: #00000035;
	--gray-a8: #00000047;
	--gray-a9: #00000078;
	--gray-a10: #00000083;
	--gray-a11: #000000a0;
	--gray-a12: #000000df;

	--gray-contrast: #FFFFFF;
	--gray-surface: #ffffffcc;
	--gray-indicator: #848484;
	--gray-track: #848484;
}

@supports (color: color(display-p3 1 1 1)) {
	@media (color-gamut: p3) {

		:root,
		.light,
		.light-theme {
			--gray-1: oklch(97.6% 0 none);
			--gray-2: oklch(96.4% 0 none);
			--gray-3: oklch(93.6% 0 none);
			--gray-4: oklch(91.1% 0 none);
			--gray-5: oklch(88.8% 0 none);
			--gray-6: oklch(86.3% 0 none);
			--gray-7: oklch(82.8% 0 none);
			--gray-8: oklch(76.8% 0 none);
			--gray-9: oklch(61.5% 0 none);
			--gray-10: oklch(58% 0 none);
			--gray-11: oklch(47.7% 0 none);
			--gray-12: oklch(24% 0 none);

			--gray-a1: color(display-p3 0 0 0 / 0.012);
			--gray-a2: color(display-p3 0 0 0 / 0.028);
			--gray-a3: color(display-p3 0 0 0 / 0.064);
			--gray-a4: color(display-p3 0 0 0 / 0.096);
			--gray-a5: color(display-p3 0 0 0 / 0.128);
			--gray-a6: color(display-p3 0 0 0 / 0.16);
			--gray-a7: color(display-p3 0 0 0 / 0.208);
			--gray-a8: color(display-p3 0 0 0 / 0.28);
			--gray-a9: color(display-p3 0 0 0 / 0.472);
			--gray-a10: color(display-p3 0 0 0 / 0.512);
			--gray-a11: color(display-p3 0 0 0 / 0.628);
			--gray-a12: color(display-p3 0 0 0 / 0.876);

			--gray-contrast: #FFFFFF;
			--gray-surface: color(display-p3 1 1 1 / 80%);
			--gray-indicator: oklch(61.5% 0 none);
			--gray-track: oklch(61.5% 0 none);
		}
	}
}

:root,
.light,
.light-theme,
.radix-themes {
	--color-background: #fafafa;
}

.rt-BaseDialogOverlay {
	z-index: 10000 !important;
}

[data-radix-popper-content-wrapper] {
	z-index: 10001 !important;
}

.ag-cell-wrapper {
	height: 100% !important;
}

* {
	@apply [&::-webkit-scrollbar]:transition-colors;
	@apply [&::-webkit-scrollbar-thumb]:rounded-full;
	@apply [&::-webkit-scrollbar-thumb]:bg-dunbar-purple-400;
	@apply hover:[&::-webkit-scrollbar-thumb]:bg-dunbar-purple;
	@apply active:[&::-webkit-scrollbar-thumb]:bg-dunbar-purple-900;
	@apply [&::-webkit-scrollbar-track]:bg-transparent;
	@apply [&::-webkit-scrollbar]:w-2;
	@apply [&::-webkit-scrollbar]:h-2;
}

@keyframes progress {
	0% { width: 0; }
	100% { width: 100%; }
}

.animate-progress {
	animation: progress 1.5s linear;
}

@keyframes check-animation {
	0% { stroke-dashoffset: 24; }
	100% { stroke-dashoffset: 0; }
}

.check-path {
	stroke-dasharray: 24;
	stroke-dashoffset: 0;
	animation: check-animation 0.5s ease-out;
}

.animate-check .check-path {
	animation: check-animation 0.5s ease-out;
}
